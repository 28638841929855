<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md
        >
          <b-card-body>
            <b-card-title>{{ course.title }}</b-card-title>
            <b-card-sub-title>
              <ul class="pl-1">
                <li
                  v-for="(item, key) in course.description"
                  :key="`desc-${key}`"
                >
                  {{ item }}
                </li>
              </ul>
            </b-card-sub-title>
            <div v-if="course.learn">
              <h5>Ваш ребенок научится</h5>
              <ul
                class="pl-0 mb-0"
                type="none"
              >
                <li
                  v-for="(item, key) in course.learn"
                  :key="`learn-${key}`"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </b-card-body>
        </b-col>
        <b-col
          cols="12"
          md="auto"
        >
          <div class="d-flex flex-column full-height align-items-stretch align-items-md-end">
            <div class="p-1 text-center">
              <p class="font-weight-bold font-medium-5 text-primary">
                <span v-if="course.price">{{ course.price }} руб</span>
                <span v-else>Бесплатно</span>
              </p>
            </div>
            <div class="flex-fill" />
            <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end">
              <b-button
                variant="flat-primary"
                href="https://rupetitor.ru/courses.php"
                target="_blank"
              >
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                Подробнее
              </b-button>
              <b-button
                v-if="!course.price"
                class="mt-1 mt-md-0 ml-0 ml-md-1"
                variant="primary"
                :href="courseUrl"
              >
                Бесплатный урок
              </b-button>
              <template v-else-if="course.purchase">
                <b-button
                  v-if="purchased"
                  class="mt-1 mt-md-0 ml-0 ml-md-1"
                  variant="primary"
                  :href="courseUrl"
                >
                  <feather-icon
                    icon="ExternalLinkIcon"
                    class="mr-50"
                  />
                  Перейти
                </b-button>
                <b-button
                  v-else
                  class="mt-1 mt-md-0 ml-0 ml-md-1"
                  variant="primary"
                  :disabled="purchased === null"
                  @click="buyCourse"
                >
                  <b-spinner
                    v-if="purchased === null"
                    small
                    class="mr-50"
                  />
                  <feather-icon
                    v-else
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  Купить
                </b-button>
              </template>
              <b-button
                v-else
                class="mt-1 mt-md-0 ml-0 ml-md-1"
                disabled
                variant="primary"
              >
                <feather-icon
                  icon="ClockIcon"
                  class="mr-50"
                />
                Скоро
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <buy-confirmation
      ref="buyConfirmationModal"
      :course="course"
      @onContinue="purchase"
    />
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardTitle, BCardSubTitle,
  BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import BuyConfirmation from '../../views/interactive/BuyConfirmation.vue'
import useJwt from '../../auth/jwt/useJwt'
import { toast } from '../../helpers'

export default {
  name: 'InteractiveCourseCard',
  components: {
    BuyConfirmation,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BButton,
    BSpinner,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    course: {
      type: Object,
      required: true,
    },
    purchased: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    hasCoursePayed: null,
  }),
  computed: {
    ...mapGetters({
      balance: 'user/get_balance',
    }),
    courseUrl() {
      const umikum = 'https://interactive.rupetitor.ru/kursmem'
      const accessToken = useJwt.getToken()
      return `${umikum}?accessToken=${accessToken}`
    },
  },
  methods: {
    buyCourse() {
      if (parseFloat(this.balance ?? '0') < parseFloat(this.course.price)) {
        this.$emit('onInsufficientFunds')
        return
      }
      this.$refs.buyConfirmationModal.show()
    },
    purchase() {
      const { type } = this
      this.$http.post('/courses/payment', { type }).then(() => {
        this.$emit('onPurchased')
        toast({
          title: 'Оплата прошла', text: 'Вы получили доступ к выбранному курсу', type: 'success',
        })
        this.$refs.buyConfirmationModal.close()
      }).catch(() => {

      })
    },
  },
}
</script>

<style scoped>

</style>
