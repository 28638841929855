<template>
  <b-modal
    v-model="display"
    title="Подтвердите списание"
    hide-footer
    centered
    no-body
    body-class="p-0"
  >
    <template #default="{ hide }">
      <b-form @submit.prevent="$emit('onContinue')">

        <div class="p-2">
          <b-row>
            <b-col
              cols
              align-self="center"
            >
              <p class="mb-0 font-weight-bold">
                {{ course.title }}
              </p>
            </b-col>
            <b-col
              cols="auto"
              align-self="center"
            >
              <p class="font-weight-bolder font-large-1 text-primary mb-0">
                {{ course.price }} <span class="font-medium-2">руб</span>
              </p>
            </b-col>
          </b-row>
        </div>
        <div class="px-2 pb-1">
          <span class="text-body font-small-3">После нажатия "Оплатить", произойдет списание средств с баланса платформы.</span>
        </div>
        <div class="thin-divider" />
        <div class="p-2 d-flex align-items-center justify-content-between">
          <b-button
            variant="primary"
            type="submit"
          >
            Оплатить
          </b-button>
          <b-button
            variant="flat-secondary"
            type="button"
            @click="hide"
          >
            {{ $t('components.button.labels.cancel') }}
          </b-button>
        </div>
      </b-form>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton, BForm, BModal, BRow, BCol,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'BuyConfirmation',
  components: {
    BModal, BButton, BForm, BRow, BCol,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    display: false,
  }),
  computed: {
    ...mapGetters({
      balance: 'user/get_balance',
    }),
  },
  methods: {
    show() {
      this.display = true
    },
    close() {
      this.display = false
    },
  },
}
</script>

<style scoped>

</style>
