<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="3"
      >
        <b-card
          class="sticky-top"
          style="top: 108px"
        >
          <div class="p-1">
            <p>Тип курса</p>
            <b-form-radio-group
              v-model="filter.type"
            >
              <div class="d-flex flex-column">
                <b-form-radio
                  value="interactive"
                  class="mb-50"
                >
                  <span>Интерактивные курсы</span>
                </b-form-radio>
                <b-form-radio
                  value="live"
                  class="mb-50"
                >
                  <span>Онлайн курсы с репетитором</span>
                </b-form-radio>
              </div>
            </b-form-radio-group>
          </div>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="9"
      >
        <transition-group
          name="fade"
        >
          <div
            v-if="filter.type === 'interactive'"
            key="interactive"
          >
            <interactive-course-card
              v-for="(course, key) in courses.interactive"
              :key="key"
              :course="course"
              :type="filter.type"
              :purchased="purchased"
              @onInsufficientFunds="() => $refs.addToBalanceModal.show()"
              @onPurchased="onPurchased"
            />
          </div>
          <div
            v-else
            key="live"
          >
            <interactive-course-card
              v-for="(course, key) in courses.live"
              :key="key"
              :course="course"
              :type="filter.type"
              :purchased="purchased"
              @onInsufficientFunds="() => $refs.addToBalanceModal.show()"
              @onPurchased="onPurchased"
            />
          </div>
        </transition-group>
      </b-col>
    </b-row>

    <create-payment-modal
      ref="addToBalanceModal"
      no-money-alert
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormRadioGroup, BFormRadio,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import InteractiveCourseCard from '../../components/interactive/InteractiveCourseCard.vue'
import CreatePaymentModal from '../../components/profile/modals/CreatePaymentModal.vue'

export default {
  name: 'InteractiveCourses',
  components: {
    CreatePaymentModal,
    InteractiveCourseCard,
    BRow,
    BCol,
    BCard,
    BFormRadioGroup,
    BFormRadio,
  },
  data: () => ({
    filter: {
      type: 'interactive',
    },
    purchased: null,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      settings: 'app/settings',
    }),
    interactivePrice() {
      return this.settings?.type_payment_interactive || 1440
    },
    livePrice() {
      return this.settings?.type_payment_live || 4320
    },
    courses() {
      return {
        interactive: [
          {
            title: 'Курс "Развитие памяти, внимания и эффективного чтения". Вводный урок',
            description: [
              'Ваш ребенок узнает секрет суперпамяти!',
              'Запомнить в 2-4 раза лучше уже на пробном занятии!',
            ],
            moreUrl: '',
            free: true,
            price: 0,
          },

          {
            title: 'Курс "Развитие памяти, внимания и эффективного чтения". Ступень 1',
            description: [
              'Основы принципов запоминания и эффективного чтения',
              'Курс 8 занятий',
              'Продолжительность урока 30-50 минут',
            ],
            learn: [
              'Запоминать текст и на слух в 3-5 раз лучше, запоминать за урок связи информации',
              'Запоминать последовательности из 12-15 слов',
            ],
            moreUrl: '',
            purchase: true,
            price: this.interactivePrice,
          },

          {
            title: 'Курс "Развитие памяти, внимания и эффективного чтения". Ступень 2',
            description: [
              'Метод избавления от ошибок. Отработка навыка эффективного чтения',
              'Курс 8 занятий',
              'Продолжительность урока 30-40 минут',
            ],
            learn: [
              'Запоминать правильное написание слов без зубрежки с помощью мнемотехники',
              'Начнем расширять угол зрения при чтении и избавляться от проговаривания',
              'Продолжаем развивать воображение, память и внимание',
            ],
            moreUrl: '',
            price: this.interactivePrice,
          },

          {
            title: 'Курс "Развитие памяти, внимания и эффективного чтения". Ступень 3',
            description: [
              'Стихотворения, цитаты, текты дословно. Отработка навыка эффективного чтения',
              'Курс 8 занятий',
              'Продолжительность урока 30-50 минут',
            ],
            learn: [
              'Учимся запоминать стихотворения, цитаты, текст дословно',
              'Продолжаем расширять угол зрения при чтении и избавляться от проговаривания',
              'Продолжаем развивать воображение, память и внимание',
            ],
            moreUrl: '',
            price: this.interactivePrice,
          },

          {
            title: 'Курс "Развитие памяти, внимания и эффективного чтения". Ступень 4',
            description: [
              'Термины и названия. Отработка навыка эффективного чтения',
              'Курс 8 занятий',
              'Продолжительность урока 30-50 минут',
            ],
            learn: [
              'Учимся запоминать термины и названия',
              'Продолжаем расширять угол зрения при чтении и избавляться от проговаривания',
              'Продолжаем развивать воображение, память и внимание',
            ],
            moreUrl: '',
            price: this.interactivePrice,
          },

          {
            title: 'Курс "Развитие памяти, внимания и эффективного чтения". Ступень 5',
            description: [
              'Определения и правила. Отработка навыка эффективного чтения',
              'Курс 8 занятий',
              'Продолжительность урока 30-50 минут',
            ],
            learn: [
              'Учимся запоминать определения и правила',
              'Продолжаем расширять угол зрения при чтении и избавляться от проговаривания',
              'Продолжаем развивать воображение, память и внимание',
            ],
            moreUrl: '',
            price: this.interactivePrice,
          },

          {
            title: 'Курс "Развитие памяти, внимания и эффективного чтения". Ступень 6',
            description: [
              'Структуры информации и классификации. Отработка навыка эффективного чтения',
              'Курс 8 занятий',
              'Продолжительность урока 30-50 минут',
            ],
            learn: [
              'Учимся запоминать структуры информации и классификации',
              'Продолжаем расширять угол зрения при чтении и избавляться от проговаривания',
              'Продолжаем развивать воображение, память и внимание',
            ],
            moreUrl: '',
            price: this.interactivePrice,
          },

          {
            title: 'Курс "Развитие памяти, внимания и эффективного чтения". Ступень 7',
            description: [
              'Цвета и флаги. Отработка навыка эффективного чтения',
              'Курс 8 занятий',
              'Продолжительность урока 30-50 минут',
            ],
            learn: [
              'Учимся запоминать абстрактную информацию. Запоминаем цвета и флаги',
              'Продолжаем расширять угол зрения при чтении и избавляться от проговаривания',
              'Продолжаем развивать воображение, память и внимание',
            ],
            moreUrl: '',
            price: this.interactivePrice,
          },

          {
            title: 'Курс "Развитие памяти, внимания и эффективного чтения". Ступень 8',
            description: [
              'Имена и лица. Отработка навыка эффективного чтения',
              'Курс 8 занятий',
              'Продолжительность урока 30-50 минут',
            ],
            learn: [
              'Учимся запоминать абстрактную информацию. Запоминаем имена и лица',
              'Продолжаем расширять угол зрения при чтении и избавляться от проговаривания',
              'Продолжаем развивать воображение, память и внимание',
            ],
            moreUrl: '',
            price: this.interactivePrice,
          },
        ],
        live: [
          {
            title: 'Курс "Развитие памяти, внимания и эффективного чтения". Вводный урок',
            description: [
              'Ваш ребенок узнает секрет суперпамяти!',
              'Запомнить в 2-4 раза лучше уже на пробном занятии!',
            ],
            moreUrl: '',
            free: true,
            price: 0,
          },

          {
            title: 'Курс "Развитие памяти, внимания и эффективного чтения". Ступень 1',
            description: [
              'Основы принципов запоминания и эффективного чтения',
              'Курс 8 уроков',
              'Продолжительность урока 30-50 минут',
            ],
            learn: [
              'Запоминать текст и на слух в 3-5 раз лучше, запоминать за урок связи информации',
              'Запоминать последовательности из 12-15 слов',
            ],
            moreUrl: '',
            purchase: true,
            price: this.livePrice,
          },

          {
            title: 'Курс "Развитие памяти, внимания и эффективного чтения". Ступень 2',
            description: [
              'Метод избавления от ошибок. Отработка навыка эффективного чтения',
              'Курс 8 уроков',
              'Продолжительность урока 30-40 минут',
            ],
            learn: [
              'Запоминать правильное написание слов без зубрежки с помощью мнемотехники',
              'Начнем расширять угол зрения при чтении и избавляться от проговаривания',
              'Продолжаем развивать воображение, память и внимание',
            ],
            moreUrl: '',
            price: this.livePrice,
          },

          {
            title: 'Курс "Развитие памяти, внимания и эффективного чтения". Ступень 3',
            description: [
              'Стихотворения, цитаты, текты дословно. Отработка навыка эффективного чтения',
              'Курс 8 уроков',
              'Продолжительность урока 30-50 минут',
            ],
            learn: [
              'Учимся запоминать стихотворения, цитаты, текст дословно',
              'Продолжаем расширять угол зрения при чтении и избавляться от проговаривания',
              'Продолжаем развивать воображение, память и внимание',
            ],
            moreUrl: '',
            price: this.livePrice,
          },

          {
            title: 'Курс "Развитие памяти, внимания и эффективного чтения". Ступень 4',
            description: [
              'Термины и названия. Отработка навыка эффективного чтения',
              'Курс 8 уроков',
              'Продолжительность урока 30-50 минут',
            ],
            learn: [
              'Учимся запоминать термины и названия',
              'Продолжаем расширять угол зрения при чтении и избавляться от проговаривания',
              'Продолжаем развивать воображение, память и внимание',
            ],
            moreUrl: '',
            price: this.livePrice,
          },

          {
            title: 'Курс "Развитие памяти, внимания и эффективного чтения". Ступень 5',
            description: [
              'Определения и правила. Отработка навыка эффективного чтения',
              'Курс 8 уроков',
              'Продолжительность урока 30-50 минут',
            ],
            learn: [
              'Учимся запоминать определения и правила',
              'Продолжаем расширять угол зрения при чтении и избавляться от проговаривания',
              'Продолжаем развивать воображение, память и внимание',
            ],
            moreUrl: '',
            price: this.livePrice,
          },

          {
            title: 'Курс "Развитие памяти, внимания и эффективного чтения". Ступень 6',
            description: [
              'Структуры информации и классификации. Отработка навыка эффективного чтения',
              'Курс 8 уроков',
              'Продолжительность урока 30-50 минут',
            ],
            learn: [
              'Учимся запоминать структуры информации и классификации',
              'Продолжаем расширять угол зрения при чтении и избавляться от проговаривания',
              'Продолжаем развивать воображение, память и внимание',
            ],
            moreUrl: '',
            price: this.livePrice,
          },

          {
            title: 'Курс "Развитие памяти, внимания и эффективного чтения". Ступень 7',
            description: [
              'Цвета и флаги. Отработка навыка эффективного чтения',
              'Курс 8 уроков',
              'Продолжительность урока 30-50 минут',
            ],
            learn: [
              'Учимся запоминать абстрактную информацию. Запоминаем цвета и флаги',
              'Продолжаем расширять угол зрения при чтении и избавляться от проговаривания',
              'Продолжаем развивать воображение, память и внимание',
            ],
            moreUrl: '',
            price: this.livePrice,
          },

          {
            title: 'Курс "Развитие памяти, внимания и эффективного чтения". Ступень 8',
            description: [
              'Имена и лица. Отработка навыка эффективного чтения',
              'Курс 8 уроков',
              'Продолжительность урока 30-50 минут',
            ],
            learn: [
              'Учимся запоминать абстрактную информацию. Запоминаем имена и лица',
              'Продолжаем расширять угол зрения при чтении и избавляться от проговаривания',
              'Продолжаем развивать воображение, память и внимание',
            ],
            moreUrl: '',
            price: this.livePrice,
          },
        ],
      }
    },
  },
  watch: {
    'filter.type': {
      handler() {
        this.checkPayment()
      },
    },
  },
  mounted() {
    this.checkPayment()
  },
  methods: {
    async checkPayment() {
      this.purchased = null
      const { result } = await this.$http.post('/user/has-course', {
        id: this.user.id,
        type: this.filter.type,
      })
      this.purchased = result
    },
    onPurchased() {
      this.purchased = true
    },
  },
}
</script>

<style scoped>

</style>
